import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { now, today, isWeekend } from '@internationalized/date';
import { DatePicker, DateField, Calendar, TimePicker, NativeDatePicker, nativeDateToDateValue, nativeDateToTimeValue, timeOrDateValueToNativeDate } from '@entur/datepicker';
import { SmallAlertBox } from '@entur/alert';
import { CodeText } from '@entur/typography';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import { CodeBlock } from '~/components/CodeBlock';
import { datepicker } from './props';
import './styles.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [date, setDate] = React.useState(now(\'Europe/Oslo\'))\n  return (\n    <DatePicker\n      label=\"Dato\"\n      selectedDate={date}\n      onChange={date => setDate(date)}\n      locale=\"nb-NO\"\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      today,
      isWeekend,
      DatePicker,
      DateField,
      Calendar,
      TimePicker,
      NativeDatePicker,
      nativeDateToDateValue,
      nativeDateToTimeValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      CodeText,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      datepicker,
      DefaultLayout,
      _frontmatter
    }} defaultShowEditor style={{
      display: 'flex',
      justifyContent: 'center',
      overflow: 'visible'
    }} mdxType="Playground">
  {() => {
        const [date, setDate] = React.useState(now('Europe/Oslo'));
        return <DatePicker label="Dato" selectedDate={date} onChange={date => setDate(date)} locale="nb-NO" mdxType="DatePicker" />;
      }}
    </Playground>
    <h2 {...{
      "id": "bruk"
    }}>{`Bruk`}</h2>
    <p>{`DatePicker hjelper brukeren å velge en dato – og evt. et tidspunkt. Den bruker pakken @internationalized/date i bakgrunnen til håndtering av dato-objektet, inkludert tidssoner – dette er tilsvarende i TimePicker.
DatePicker støtter også ulike locals og språk. DatePicker bygger på react-aria og støtter det meste av funksjonalitet du finner der, `}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/useDatePicker.html"
      }}>{`les mer her`}</a>{`.`}</p>
    <SmallAlertBox variant="information" mdxType="SmallAlertBox">
  <div>
    <strong>OBS:</strong> hjelpefunksjoner fra{' '}
    <CodeText mdxType="CodeText">@internationalized/date</CodeText> (typ.{' '}
    <CodeText mdxType="CodeText">now()</CodeText> og <CodeText mdxType="CodeText">isWeekend()</CodeText>) er ikke
    inkludert i <CodeText mdxType="CodeText">@entur/datepicker</CodeText>, legg til{' '}
    <CodeText mdxType="CodeText">@internationalized/date</CodeText> i repo ditt for å bruke dem.
  </div>
    </SmallAlertBox>
    <h3 {...{
      "id": "språk-og-locale"
    }}>{`Språk og locale`}</h3>
    <p>{`Språk og locals er støttet gjennom to metoder. All automatisk tilpassing av språk skjer gjennom prop-en `}<inlineCode parentName="p">{`locale`}</inlineCode>{` eller `}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/I18nProvider.html"
      }}>{`react-aria`}</a>{` sin `}<inlineCode parentName="p">{`<I18nProvider />`}</inlineCode>{`.
`}<inlineCode parentName="p">{`locale`}</inlineCode>{` støtter strenger på BCP 47-formatet, eks. `}<inlineCode parentName="p">{`nb-NO`}</inlineCode>{` for norsk. Her finner du en `}<a parentName="p" {...{
        "href": "https://www.techonthenet.com/js/language_tags.php"
      }}>{`liste over BCP 47-koder`}</a>{`. Som default velges den locale-en som er satt på brukeren maskin.`}</p>
    <p>{`Ledeteksten (label), og `}<inlineCode parentName="p">{`navigationDescription`}</inlineCode>{`*`}{` må sende inn manuell oversettelse for. For `}<inlineCode parentName="p">{`navigationDescription`}</inlineCode>{` vil en norsk og engelsk verdi følge med automatisk, men andre språk må legges inn selv.`}</p>
    <p>{`*`}<inlineCode parentName="p">{`navigationDescription`}</inlineCode>{` er en prop som forteller brukeren hvordan de kan navigere i kalenderen med tastaturet.`}</p>
    <h4 {...{
      "id": "datepicker-tilpasset-usa"
    }}>{`DatePicker tilpasset USA`}</h4>
    <Playground __position={3} __code={'() => {\n  const [date, setDate] = React.useState(now(\'Europe/Oslo\'))\n  return (\n    <DatePicker\n      label=\"Date\"\n      selectedDate={date}\n      onChange={date => setDate(date)}\n      locale=\"en-US\"\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      today,
      isWeekend,
      DatePicker,
      DateField,
      Calendar,
      TimePicker,
      NativeDatePicker,
      nativeDateToDateValue,
      nativeDateToTimeValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      CodeText,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      datepicker,
      DefaultLayout,
      _frontmatter
    }} defaultShowEditor hideContrastOption style={{
      overflow: 'visible'
    }} mdxType="Playground">
  {() => {
        const [date, setDate] = React.useState(now('Europe/Oslo'));
        return <DatePicker label="Date" selectedDate={date} onChange={date => setDate(date)} locale="en-US" mdxType="DatePicker" />;
      }}
    </Playground>
    <h3 {...{
      "id": "tidssoner"
    }}>{`Tidssoner`}</h3>
    <p>{`TimePicker støtter tidssonehåndtering for å sikre lik opplevelse på tvers av tidssoner. Dette håndteres ved `}<inlineCode parentName="p">{`@internationalized/date`}</inlineCode>{` sitt `}<inlineCode parentName="p">{`ZonedDateTime`}</inlineCode>{`-objekt.
Under er et eksempel på hvordan du lager state for nåværende dato og tidspunkt i norsk tidssone, og for et spesifikt tidspunkt i Los Angeles sin tidssone:`}</p>
    <CodeBlock hideLineNumbers wrapLongLines={false} language="ts" mdxType="CodeBlock">
  {`// nåværende tidspunkt i norsk tidssone
const [date, setDate] = React.useState(now('Europe/Oslo'));
// spesifikt tidspunkt i Los Angeles sin tidssone
const [date2, setDate2] = React.useState(parseZonedDateTime('2022-11-07T00:45[America/Los_Angeles]'));`}
    </CodeBlock>
    <p><a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/internationalized/date/ZonedDateTime.html"
      }}>{`Les mer om hvordan opprette og bruke tidssonefunksjonalitet her`}</a>{`.`}</p>
    <h3 {...{
      "id": "datovalidering"
    }}>{`Datovalidering`}</h3>
    <p>{`Hvis du ønsker å begrense tilgjengelige datoer for brukeren, samt gi en tilbakemelding når en dato utenfor dette intervallet er valgt, kan du benytte
`}<inlineCode parentName="p">{`minDate`}</inlineCode>{`- og `}<inlineCode parentName="p">{`maxDate`}</inlineCode>{`-props-ene eller `}<inlineCode parentName="p">{`isDateUnavailable()`}</inlineCode>{`-valideringsfunksjonen. `}<inlineCode parentName="p">{`minDate`}</inlineCode>{` og `}<inlineCode parentName="p">{`maxDate`}</inlineCode>{` tar inn et `}<inlineCode parentName="p">{`CalendarDate`}</inlineCode>{`-objekt.
`}<inlineCode parentName="p">{`isDateUnavailable()`}</inlineCode>{` skal ta inn en `}<inlineCode parentName="p">{`DateValue`}</inlineCode>{` og returnerer en `}<inlineCode parentName="p">{`boolean`}</inlineCode>{` for om datoen er gyldig.`}</p>
    <h4 {...{
      "id": "validering-med-mindate-og-maxdate"
    }}>{`Validering med minDate og maxDate`}</h4>
    <p>{`Følgende eksempel godtar datoer fra og med i dag til og med en måned frem:`}</p>
    <Playground __position={5} __code={'() => {\n  const [date, setDate] = React.useState(\n    today(\'Europe/Oslo\').add({ months: 2 }),\n  )\n  return (\n    <DatePicker\n      label=\"Velg dato\"\n      selectedDate={date}\n      onChange={setDate}\n      locale=\"nb-NO\"\n      minDate={today(\'Europe/Oslo\')}\n      maxDate={today(\'Europe/Oslo\').add({ months: 1 })}\n      validationFeedback=\"Valgt dato er for langt frem i tid\"\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      today,
      isWeekend,
      DatePicker,
      DateField,
      Calendar,
      TimePicker,
      NativeDatePicker,
      nativeDateToDateValue,
      nativeDateToTimeValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      CodeText,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      datepicker,
      DefaultLayout,
      _frontmatter
    }} defaultShowEditor hideContrastOption style={{
      overflow: 'visible'
    }} mdxType="Playground">
  {() => {
        const [date, setDate] = React.useState(today('Europe/Oslo').add({
          months: 2
        }));
        return <DatePicker label="Velg dato" selectedDate={date} onChange={setDate} locale="nb-NO" minDate={today('Europe/Oslo')} maxDate={today('Europe/Oslo').add({
          months: 1
        })} validationFeedback="Valgt dato er for langt frem i tid" mdxType="DatePicker" />;
      }}
    </Playground>
    <h4 {...{
      "id": "validering-med-isdateunavailable"
    }}>{`Validering med isDateUnavailable`}</h4>
    <p>{`Følgende eksempel godtar datoer som ikke er en helg.`}</p>
    <Playground __position={6} __code={'() => {\n  const [date, setDate] = React.useState(now(\'Europe/Oslo\'))\n  return (\n    <DatePicker\n      label=\"Velg dato\"\n      selectedDate={date}\n      onChange={setDate}\n      locale=\"nb-NO\"\n      isDateUnavailable={date => isWeekend(date, \'nb-NO\')}\n      validationFeedback=\"Valgt dato kan ikke være en helgedag\"\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      today,
      isWeekend,
      DatePicker,
      DateField,
      Calendar,
      TimePicker,
      NativeDatePicker,
      nativeDateToDateValue,
      nativeDateToTimeValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      CodeText,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      datepicker,
      DefaultLayout,
      _frontmatter
    }} defaultShowEditor hideContrastOption style={{
      overflow: 'visible'
    }} mdxType="Playground">
  {() => {
        const [date, setDate] = React.useState(now('Europe/Oslo'));
        return <DatePicker label="Velg dato" selectedDate={date} onChange={setDate} locale="nb-NO" isDateUnavailable={date => isWeekend(date, 'nb-NO')} validationFeedback="Valgt dato kan ikke være en helgedag" mdxType="DatePicker" />;
      }}
    </Playground>
    <h3 {...{
      "id": "bruke-js-date-i-stedet-for-internationalizeddate"
    }}>{`Bruke JS Date i stedet for @internationalized/date`}</h3>
    <p>{`Hvis du ikke har mulighet til å bruke `}<inlineCode parentName="p">{`@internationalized/date`}</inlineCode>{`, kan du bruke konverteringsfunksjonene: `}<inlineCode parentName="p">{`nativeDateToDateValue`}</inlineCode>{` og `}<inlineCode parentName="p">{`timeOrDateValueToNativeDate`}</inlineCode>{`. Disse konverterer
mellom `}<inlineCode parentName="p">{`@internationalized/date`}</inlineCode>{` sine tre dato-typer: `}<inlineCode parentName="p">{`ZonedDateTime`}</inlineCode>{`, `}<inlineCode parentName="p">{`CalendarDateTime`}</inlineCode>{` og `}<inlineCode parentName="p">{`CalendarDate`}</inlineCode>{` (`}<inlineCode parentName="p">{`DateValue`}</inlineCode>{` er en samling av disse tre typene) og Javascript sin `}<inlineCode parentName="p">{`Date`}</inlineCode>{`.
Se API under, nærmere beskrivelse finnes i JSDocs for funksjonene:`}</p>
    <CodeBlock hideLineNumbers wrapLongLines={false} language="ts" mdxType="CodeBlock">
  {`nativeDateToDateValue: (date: Date | null, noTimeOnlyDate?: boolean, timeZone?: string | undefined, offset?: number | undefined) => CalendarDateTime | ZonedDateTime | CalendarDate | null;
timeOrDateValueToNativeDate: (value: TimeValue | DateValue | null, timeZoneForCalendarDateTime?: string | undefined) => Date | null;`}
    </CodeBlock>
    <h4 {...{
      "id": "eksempel-på-bruk-av-js-date-med-datepicker"
    }}>{`Eksempel på bruk av JS-date med DatePicker`}</h4>
    <CodeBlock asExpandable expandableLabel="JS Date-eksempel" mdxType="CodeBlock">
  {`const [date, setDate] = useState(new Date());
  return (
    <DatePicker
      label="Choose date"
      selectedDate={nativeDateToDateValue(date)}
      onChange={d => setDate(timeOrDateValueToNativeDate(d))}
    />
  );`}
    </CodeBlock>
    <CodeBlock asExpandable expandableLabel="JS Date med tidssone-eksempel" mdxType="CodeBlock">
  {`const [date, setDate] = useState(new Date());
  return (
      <DatePicker
        label="Choose date"
        selectedDate={
          nativeDateToDateValue(
            date,
            false,
            'Europe/Oslo',
          )
        }
        onChange={d => setDate(timeOrDateValueToNativeDate(d))}
      />
  );`}
    </CodeBlock>
    <h3 {...{
      "id": "bruk-sammen-med-timepicker"
    }}>{`Bruk sammen med TimePicker`}</h3>
    <p>{`Du kan velge et tidspunkt sammen med datoen på to ulike måter, enten inline med `}<inlineCode parentName="p">{`showTime`}</inlineCode>{`-prop-en eller ved å bruke en `}<inlineCode parentName="p">{`TimePicker`}</inlineCode>{` i kombinasjon med `}<inlineCode parentName="p">{`DatePicker`}</inlineCode>{`-en.`}</p>
    <h4 {...{
      "id": "inline"
    }}>{`Inline`}</h4>
    <Playground __position={10} __code={'() => {\n  const [date, setDate] = React.useState(now(\'Europe/Oslo\'))\n  return (\n    <DatePicker\n      label=\"Velg dato og tid\"\n      selectedDate={date}\n      onChange={setDate}\n      locale=\"nb-NO\"\n      showTime\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      today,
      isWeekend,
      DatePicker,
      DateField,
      Calendar,
      TimePicker,
      NativeDatePicker,
      nativeDateToDateValue,
      nativeDateToTimeValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      CodeText,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      datepicker,
      DefaultLayout,
      _frontmatter
    }} defaultShowEditor hideContrastOption style={{
      overflow: 'visible'
    }} mdxType="Playground">
  {() => {
        const [date, setDate] = React.useState(now('Europe/Oslo'));
        return <DatePicker label="Velg dato og tid" selectedDate={date} onChange={setDate} locale="nb-NO" showTime mdxType="DatePicker" />;
      }}
    </Playground>
    <h4 {...{
      "id": "kombinasjon-med-timepicker"
    }}>{`Kombinasjon med TimePicker`}</h4>
    <p>{`I løsninger ut mot vanlige sluttbrukere er en separat dato- og tid-velger foretrukket.`}</p>
    <Playground __position={11} __code={'() => {\n  const [dateTime, setDateTime] = React.useState(null)\n  return (\n    <div style={{ display: \'flex\', gap: \'1rem\' }}>\n      <DatePicker\n        label=\"Dato\"\n        selectedDate={dateTime}\n        onChange={setDateTime}\n        locale=\"nb-NO\"\n        // \'forcedReturnType\' er nødvendig når\n        // initiell state er \'null\'\n        forcedReturnType=\"ZonedDateTime\"\n      />\n      <TimePicker\n        label=\"Tid\"\n        selectedTime={dateTime}\n        onChange={setDateTime}\n        locale=\"nb-NO\"\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      today,
      isWeekend,
      DatePicker,
      DateField,
      Calendar,
      TimePicker,
      NativeDatePicker,
      nativeDateToDateValue,
      nativeDateToTimeValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      CodeText,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      datepicker,
      DefaultLayout,
      _frontmatter
    }} defaultShowEditor hideContrastOption style={{
      overflow: 'visible'
    }} mdxType="Playground">
  {() => {
        const [dateTime, setDateTime] = React.useState(null);
        return <div style={{
          display: 'flex',
          gap: '1rem'
        }}>
        <DatePicker label="Dato" selectedDate={dateTime} onChange={setDateTime} locale="nb-NO" // 'forcedReturnType' er nødvendig når
          // initiell state er 'null'
          forcedReturnType="ZonedDateTime" mdxType="DatePicker" />
        <TimePicker label="Tid" selectedTime={dateTime} onChange={setDateTime} locale="nb-NO" mdxType="TimePicker" />
      </div>;
      }}
    </Playground>
    <h5 {...{
      "id": "invalid-granularity-for--feilmelding"
    }}>{`'Invalid granularity for …' feilmelding`}</h5>
    <p>{`Hvis DatePicker initialiseres med `}<inlineCode parentName="p">{`null`}</inlineCode>{` som `}<inlineCode parentName="p">{`selectedDate`}</inlineCode>{` vil den som standard returnere en `}<inlineCode parentName="p">{`CalendarDate`}</inlineCode>{` uten tidspunkt.
For å fungere sammen med en `}<inlineCode parentName="p">{`TimePicker`}</inlineCode>{` må man da tvinge den til å returnere et objekt med tid også. Dette kan du gjøre med
prop-en `}<inlineCode parentName="p">{`forcedReturnType`}</inlineCode>{`. Send inn enten `}<inlineCode parentName="p">{`ZonedDateTime`}</inlineCode>{` eller `}<inlineCode parentName="p">{`CalendarDateTime`}</inlineCode>{` avhengig av om du ønsker å sette en tidssoner eller ikke.`}</p>
    <h3 {...{
      "id": "bruk-på-mobile-enheter"
    }}>{`Bruk på mobile enheter`}</h3>
    <p>{`DatePicker fungerer også på mobile enheter. For å gjøre valg enklere for brukere med berøringskjermer benyttes en modal i stedet for en popover når skjermen er
smalere enn 1000px. På denne måten vil alltid hele datovelgeren vises når man åpner den. Dette er mulig å skru av ved å bruke prop-en `}<inlineCode parentName="p">{`disableModal`}</inlineCode>{`.`}</p>
    <p>{`Ønsker man en OS-spesifikk opplevelse av DatePicker-en, kan man benytte seg av NativeDatePicker. Denne har noe styling for å gi den et Entur-preg,
men vil bruke OS-et sin styling og interaksjonsmetode når man interagerer med den.`}</p>
    <Playground __position={12} __code={'() => {\n  return (\n    <NativeDatePicker\n      label=\"Fødselsdato\"\n      style={{ width: \'15rem\' }}\n      value=\"1997-07-10\"\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      today,
      isWeekend,
      DatePicker,
      DateField,
      Calendar,
      TimePicker,
      NativeDatePicker,
      nativeDateToDateValue,
      nativeDateToTimeValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      CodeText,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      datepicker,
      DefaultLayout,
      _frontmatter
    }} defaultShowEditor hideContrastOption mdxType="Playground">
  {() => {
        return <NativeDatePicker label="Fødselsdato" style={{
          width: '15rem'
        }} value="1997-07-10" mdxType="NativeDatePicker" />;
      }}
    </Playground>
    <h3 {...{
      "id": "kun-kalender"
    }}>{`Kun kalender`}</h3>
    <p>{`Hvis du ønsker å kun vise en inline kalender til brukeren kan du benytte `}<inlineCode parentName="p">{`<Calendar />`}</inlineCode>{`-komponenten. Denne fungerer med samme type datoobjekter som `}<inlineCode parentName="p">{`<DatePicker />`}</inlineCode>{` og støtter mange av de samme props-ene.`}</p>
    <Playground __position={13} __code={'() => {\n  const [date, setDate] = React.useState(now(\'Europe/Oslo\'))\n  return (\n    <Calendar\n      label=\"Dato\"\n      selectedDate={date}\n      onChange={date => setDate(date)}\n      locale=\"nb-NO\"\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      today,
      isWeekend,
      DatePicker,
      DateField,
      Calendar,
      TimePicker,
      NativeDatePicker,
      nativeDateToDateValue,
      nativeDateToTimeValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      CodeText,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      datepicker,
      DefaultLayout,
      _frontmatter
    }} hideContrastOption mdxType="Playground">
  {() => {
        const [date, setDate] = React.useState(now('Europe/Oslo'));
        return <Calendar label="Dato" selectedDate={date} onChange={date => setDate(date)} locale="nb-NO" mdxType="Calendar" />;
      }}
    </Playground>
    <h4 {...{
      "id": "styling-av-datoer-i-kalenderen"
    }}>{`Styling av datoer i kalenderen`}</h4>
    <p>{`Både `}<inlineCode parentName="p">{`DatePicker`}</inlineCode>{` og `}<inlineCode parentName="p">{`Calendar`}</inlineCode>{` har støtte for prop-en `}<inlineCode parentName="p">{`classNameForDate`}</inlineCode>{`. `}<inlineCode parentName="p">{`classNameForDate`}</inlineCode>{` skal være en funksjon som tar inn en dato og returnerer en streng med klassenavnet som skal legges til for dato-ruten. `}</p>
    <p>{`Hvis stylingen som legges til er meningsbærende bør du også bruke `}<inlineCode parentName="p">{`ariaLabelForDate`}</inlineCode>{` til å beskrive stylingens mening for skjermlesere o.l.`}</p>
    <Playground __position={14} __code={'() => {\n  const [date, setDate] = React.useState(now(\'Europe/Oslo\'))\n  return (\n    <Calendar\n      label=\"Dato\"\n      selectedDate={date}\n      onChange={date => setDate(date)}\n      locale=\"nb-NO\"\n      classNameForDate={date =>\n        isWeekend(date, \'nb-NO\') ? \'special-class\' : \'\'\n      }\n      ariaLabelForDate={date =>\n        isWeekend(date, \'nb-NO\') ? \', helgedag\' : \'\'\n      }\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      today,
      isWeekend,
      DatePicker,
      DateField,
      Calendar,
      TimePicker,
      NativeDatePicker,
      nativeDateToDateValue,
      nativeDateToTimeValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      CodeText,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      datepicker,
      DefaultLayout,
      _frontmatter
    }} hideContrastOption mdxType="Playground">
  {() => {
        const [date, setDate] = React.useState(now('Europe/Oslo'));
        return <Calendar label="Dato" selectedDate={date} onChange={date => setDate(date)} locale="nb-NO" classNameForDate={date => isWeekend(date, 'nb-NO') ? 'special-class' : ''} ariaLabelForDate={date => isWeekend(date, 'nb-NO') ? ', helgedag' : ''} mdxType="Calendar" />;
      }}
    </Playground>
    <h4 {...{
      "id": "vise-ukenummer"
    }}>{`Vise ukenummer`}</h4>
    <p>{`Hvis du trenger å vise ukenummer i kalenderen kan du benytte prop-en `}<inlineCode parentName="p">{`showWeekNumbers`}</inlineCode>{`. Du kan endre overskriften til ukenummerkolonnen ved å bruke prop-en `}<inlineCode parentName="p">{`weekNumberHeading`}</inlineCode>{`.`}</p>
    <Playground __position={15} __code={'() => {\n  const [date, setDate] = React.useState(now(\'Europe/Oslo\'))\n  return (\n    <Calendar\n      label=\"Dato\"\n      selectedDate={date}\n      onChange={date => setDate(date)}\n      locale=\"nb-NO\"\n      showWeekNumbers\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      today,
      isWeekend,
      DatePicker,
      DateField,
      Calendar,
      TimePicker,
      NativeDatePicker,
      nativeDateToDateValue,
      nativeDateToTimeValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      CodeText,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      datepicker,
      DefaultLayout,
      _frontmatter
    }} hideContrastOption mdxType="Playground">
  {() => {
        const [date, setDate] = React.useState(now('Europe/Oslo'));
        return <Calendar label="Dato" selectedDate={date} onChange={date => setDate(date)} locale="nb-NO" showWeekNumbers mdxType="Calendar" />;
      }}
    </Playground>
    <h3 {...{
      "id": "kun-inputfelt"
    }}>{`Kun inputfelt`}</h3>
    <p>{`Hvis du ønsker å kun vise et inputfelt uten mulighet for en kalender-popover kan du benytte `}<inlineCode parentName="p">{`<DateField />`}</inlineCode>{`-komponenten. Denne fungerer med samme type datoobjekter som `}<inlineCode parentName="p">{`<DatePicker />`}</inlineCode>{` og støtter mange av de samme props-ene.`}</p>
    <Playground __position={16} __code={'() => {\n  const [date, setDate] = React.useState(now(\'Europe/Oslo\'))\n  return (\n    <DateField\n      label=\"Dato\"\n      selectedDate={date}\n      onChange={date => setDate(date)}\n      locale=\"nb-NO\"\n      style={{ width: \'8.5rem\' }}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      today,
      isWeekend,
      DatePicker,
      DateField,
      Calendar,
      TimePicker,
      NativeDatePicker,
      nativeDateToDateValue,
      nativeDateToTimeValue,
      timeOrDateValueToNativeDate,
      SmallAlertBox,
      CodeText,
      Playground,
      ImportStatement,
      PageHeader,
      CodeBlock,
      datepicker,
      DefaultLayout,
      _frontmatter
    }} hideContrastOption mdxType="Playground">
  {() => {
        const [date, setDate] = React.useState(now('Europe/Oslo'));
        return <DateField label="Dato" selectedDate={date} onChange={date => setDate(date)} locale="nb-NO" style={{
          width: '8.5rem'
        }} mdxType="DateField" />;
      }}
    </Playground>
    <h2 {...{
      "id": "universell-utforming"
    }}>{`Universell utforming`}</h2>
    <p>{`DatePicker bruker `}<a parentName="p" {...{
        "href": "https://react-spectrum.adobe.com/react-aria/useTimeField.html"
      }}>{`react-aria`}</a>{` i bakgrunnen.Denne pakken sørger for gjennomgående støtte for universell utforming.
Hvert datosegment er tilgjengelig med tastaturet og alle interagerbare elementer, både i inputfeltet og i kalenderen, har aria-beskrivelser.`}</p>
    <h3 {...{
      "id": "hvordan-oppfylle-uu-krav"
    }}>{`Hvordan oppfylle UU-krav`}</h3>
    <p>{`Hvis du endrer `}<inlineCode parentName="p">{`locale`}</inlineCode>{` (dvs. språk) til noe annet enn norsk og engelsk må du sende inn verdier på riktig språk til `}<inlineCode parentName="p">{`navigationDescription`}</inlineCode>{`-prop-en.`}</p>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "datepicker-"
    }}>{`<DatePicker /`}{`>`}</h3>
    <ImportStatement imports="DatePicker" mdxType="ImportStatement" />
    <Props of={DatePicker} mdxType="Props" />
    <h3 {...{
      "id": "datefield-"
    }}>{`<DateField /`}{`>`}</h3>
    <ImportStatement imports="DateField" mdxType="ImportStatement" />
    <Props of={DateField} mdxType="Props" />
    <h3 {...{
      "id": "calendar-"
    }}>{`<Calendar /`}{`>`}</h3>
    <ImportStatement imports="Calendar" mdxType="ImportStatement" />
    <Props of={Calendar} mdxType="Props" />
    <h3 {...{
      "id": "nativedatepicker-"
    }}>{`<NativeDatePicker /`}{`>`}</h3>
    <ImportStatement imports="NativeDatePicker" mdxType="ImportStatement" />
    <Props of={NativeDatePicker} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      